import React, { useEffect, useState } from "react";
import { RiMenuAddFill } from "react-icons/ri";


function FilterDetail({
    GetResponseData, 
    status,
    setStatus,
    arrangement,
    setArrangement,
    waiting,
    setWaiting,
    invoice,
    setInvoice,
    setLoading
}) {
  const [isFilterText, setIsFiltertext] = useState("");
  const [isActive, setIsActive] = useState(false);

  const rolesData = JSON.parse(localStorage.getItem('roles')) || [];
  const filteredRolesDoctor = rolesData.filter(role => role.name === 'Doctor');
  const filteredRolesAdmin = rolesData.filter(role => role.name === 'Admin');

  const toggleDropdown = () => {
    setIsActive(!isActive); 
  };

  const handleStatusChange = (newStatus) => {
    setLoading(true); 
    setArrangement(null);
    setWaiting(null);
    setInvoice(null);
    setStatus(newStatus); 
    setIsActive(false); 
  };

  const handleArrangementChange = (newArrangement) => {
    setLoading(true); 
    setStatus(null); 
    setWaiting(null); 
    setInvoice(null);
    setArrangement(newArrangement); 
    setIsActive(false); 
  }

  const handleWaitingChange = (newWaiting) => {
    setLoading(true); 
    setStatus(null); 
    setArrangement(null); 
    setInvoice(null);
    setWaiting(newWaiting); 
    setIsActive(false); 
  }

  const handleInvoiceChange = (newInvoice) => {
    setLoading(true); 
    setStatus(null); 
    setArrangement(null); 
    setWaiting(null);
    setInvoice(newInvoice); 
    setIsActive(false); 
  }


  useEffect(() => {
    if (filteredRolesAdmin.length > 0) {
      if (status === 0) {
        setIsFiltertext("Appointment Terbaru");
      } else if (arrangement === "emptyArrangement") {
        setIsFiltertext("Arrangement");
      } else if (waiting === "emptyWaiting") {
        setIsFiltertext("Waiting List");
      } else if (waiting === "notEmptyWaiting") {
        setIsFiltertext("Menunggu");
      } else if (invoice === "emptyInvoice") {
        setIsFiltertext("Buat Invoice");
      } else if (invoice === "notEmptyInvoice") {
        setIsFiltertext("Upload Pembayaran");
      } else if (invoice === "clearInvoice") {
        setIsFiltertext("Lunas");
      }
    } else if (filteredRolesDoctor.length > 0) {
      if (arrangement === "emptyArrangement") {
        setIsFiltertext("Menunggu");
      } else if (waiting === "notEmptyWaiting") {
        setIsFiltertext("Treatment Plan");
      } else if (invoice === "emptyInvoice") {
        setIsFiltertext("Selesai");
      }
    }
  }, [status, arrangement, waiting, invoice, filteredRolesAdmin.length, filteredRolesDoctor.length]);


  return (
    <div className={`dropdown is-small ${isActive ? "is-active" : ""}`} style={{ width: "170px" }}>
      <div className="dropdown-trigger" style={{ width: "100%" }}>
        <button
          className="button is-small"
          aria-haspopup="true"
          aria-controls="dropdown-menu3"
          onClick={toggleDropdown}
          style={{ width: "100%", fontSize: "12px", padding: "4px 8px" }}
        >
          <span>{isFilterText || "Filter By"}</span>
          <span className="icon is-small">
            <i className="" aria-hidden="true"> <RiMenuAddFill style={{ fontSize: "18px" }} /></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu3" role="menu" style={{ width: "100%" }}>
        <div className="dropdown-content" style={{ width: "100%" }}>

            {filteredRolesAdmin.length > 0 &&
              <>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}  
                          onClick={() => handleStatusChange(0)} 
                  >
                      Appoinment Baru
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                          onClick={() => handleArrangementChange("emptyArrangement")} 
                  >
                      Arrangement
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                          onClick={() => handleWaitingChange("emptyWaiting")}
                  >
                      Waiting List
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                          onClick={() => handleWaitingChange("notEmptyWaiting")}
                          >
                      Menunggu
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                    onClick={() => handleInvoiceChange("emptyInvoice")}
                  >
                    Buat Invoice
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                    onClick={() => handleInvoiceChange("notEmptyInvoice")}
                  >
                    Upload Pembayaran
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                    onClick={() => handleInvoiceChange("clearInvoice")}
                  >
                    Lunas
                  </button>
              </>
            }
            {filteredRolesDoctor.length > 0 &&
              <>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                          onClick={() => handleArrangementChange("emptyArrangement")} 
                  >
                      Menunggu
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                          onClick={() => handleWaitingChange("notEmptyWaiting")}
                          >
                      Treatment Plan
                  </button>
                  <button className="dropdown-item is-small" style={{ fontSize: "12px" }}
                    onClick={() => handleInvoiceChange("emptyInvoice")}
                  > 
                    Selesai 
                  </button>
              </>
            }
        </div>
      </div>
    </div>
  );
}

export default FilterDetail;
